<template>
    <div>
        <h1>
            &lt;разработОЧКА... /&gt;
        </h1>
    </div>
</template>

<script>
export default {
    name: 'UnderDevelopment'
}
</script>

<style scoped>
* {
    margin: 0;
}

body>div {
    width: 100vw;
    height: 100vh;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

h1 {
    text-align: center;
    padding: 0;
}
</style>